<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  @if (messageIsArray) {
    @for (msg of message; track msg) {
      <p>
        {{ msg }}
      </p>
    }
  }
  @if (!messageIsArray) {
    {{ message }}
  }
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="decline()">
    {{ btnCancelText }}
  </button>
  @if (showAccept) {
    <button type="button" class="btn btn-outline-info" (click)="accept()">
      {{ btnOkText }}
    </button>
  }
</div>
