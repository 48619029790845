import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pae-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  standalone: true,
})
export class ConfirmationDialogComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() showAccept: boolean;

  get messageIsArray(): boolean {
    return Array.isArray(this.message);
  }

  constructor(private activeModal: NgbActiveModal) {}

  decline(): void {
    this.activeModal.close(false);
  }

  accept(): void {
    this.activeModal.close(true);
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }
}
